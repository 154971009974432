import React from 'react'
import { HStack, Tag, Text, Token } from '@revolut/ui-kit'

import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { WorkScheduleListItemInterface } from '@src/interfaces/workSchedule'
import { selectorKeys } from '../api'
import {
  getWorkingHoursNode,
  getWorkingDaysString,
} from '@src/apps/People/TimeManagement/WorkSchedule/helpers'

export const timeOffWorkScheduleNameColumn: ColumnInterface<WorkScheduleListItemInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'name',
    dataPoint: 'name',
    sortKey: 'name',
    filterKey: 'id',
    selectorsKey: selectorKeys.work_schedules,
    title: 'Name',
    insert: ({ data }) => (
      <HStack space="s-8" align="center">
        <Text>{data.name || '-'}</Text>
        {data.is_default_schedule ? <Tag variant="status">default</Tag> : null}
      </HStack>
    ),
  }

export const timeOffWorkScheduleMembersColumn: ColumnInterface<WorkScheduleListItemInterface> =
  {
    type: CellTypes.text,
    idPoint: 'members',
    dataPoint: 'members',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Members',
  }

export const timeOffWorkScheduleDaysWorkedColumn: ColumnInterface<WorkScheduleListItemInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'name',
    dataPoint: 'name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Days worked',
    insert: ({ data }) => getWorkingDaysString(data.working_days),
  }

export const timeOffWorkScheduleHoursWorkedColumn: ColumnInterface<WorkScheduleListItemInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'name',
    dataPoint: 'name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Hours worked',
    insert: ({ data }) => getWorkingHoursNode(data.working_days),
  }

export const timeOffWorkScheduleStatusColumn: ColumnInterface<WorkScheduleListItemInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'status.id',
    dataPoint: 'status.name',
    sortKey: 'status',
    filterKey: 'status',
    selectorsKey: selectorKeys.work_schedule_statuses,
    title: 'Status',
    insert: ({ data }) => {
      let color = Token.color.foreground

      if (data.status.id === 'draft') {
        color = Token.color.greyTone50
      } else if (data.status.id === 'active') {
        color = Token.color.accent
      }
      return <Text color={color}>{data.status.name}</Text>
    },
  }
