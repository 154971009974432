import React, { useEffect } from 'react'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { GoalsInterface, GoalKpiDetails } from '@src/interfaces/goals'
import { CellWithItem } from '../../../common/CellWithItem'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { ActionButton } from '@revolut/ui-kit'

import { useOrgEntity } from '@src/features/OrgEntityProvider/OrgEntityProvider'

import omit from 'lodash/omit'
import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import pick from 'lodash/pick'
import { useFormObserver } from '../FormObserverProvider'
import { GoalMetricsItems } from './GoalMetricItem'
import { KpiInterface } from '@src/interfaces/kpis'

interface TargetsWidgetProps {
  reviewCycle?: ReviewCyclesInterface
}

type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>
    }
  : T

export const TargetsWidget = ({ reviewCycle }: TargetsWidgetProps) => {
  const { values } = useLapeContext<
    GoalsInterface & { kpis: DeepPartial<GoalsInterface['kpis']> }
  >()
  const { getFormById } = useFormObserver()
  const { data: reviewCycles, isFetching } = useGetSelectors<ReviewCyclesInterface>(
    selectorKeys.review_cycles,
  )

  const { getEntityProps } = useOrgEntity()

  const onAddNewMetric = (copyFromId?: number) => {
    // when multiple goals enabled we have option to duplicate goal in the same cycle
    // need to grab correct data for it
    let formID = values.kpis.at(-1)?.id || values.kpis.at(-1)?.tempId
    const targetForm = getFormById(formID)
    let copyValues: GoalKpiDetails | KpiInterface | undefined = targetForm?.().form.values

    if (copyFromId) {
      const metric = values.kpis.find(
        kpi => kpi.id === copyFromId || kpi.tempId === copyFromId,
      )
      if (metric) {
        copyValues = metric
      }
    }

    const targetsData = copyValues?.targets?.at(0)

    const notSelectedCycles = reviewCycles
      ?.filter(
        cycle =>
          !values.kpis.some(kpi => kpi.targets.at(0)?.review_cycle?.id === cycle.id),
      )
      .sort((a, b) => Number(b.offset) - Number(a.offset))

    const nextMetricCycle = copyFromId
      ? targetsData?.review_cycle
      : notSelectedCycles?.find(
          cycle =>
            Number(cycle.offset) <
            Number(copyValues?.targets?.at(0)?.review_cycle?.offset),
        )
    const base = copyValues
      ? omit(copyValues, 'id', 'targets', 'rejection_reason', 'target_epics')
      : {}

    const targetEpics = copyValues?.target_epics?.at(0)
      ? [
          {
            ...pick(copyValues.target_epics.at(0), 'epics'),
            review_cycle: nextMetricCycle,
          },
        ]
      : undefined

    const nextMetric: DeepPartial<GoalKpiDetails> = {
      ...base,
      targets: [
        {
          ...pick(targetsData, 'kpi_goal'),
          initial_value: targetsData?.target,
          target: undefined,
          review_cycle: nextMetricCycle || notSelectedCycles?.at(0),
        },
      ],
      target_epics: targetEpics,
      tempId: Date.now(), // need a number value before metrics are submitted and have actual id. Used for targeting the form for copying data
    }
    values.kpis.push(nextMetric)
  }

  const addEmptyMetric = () => {
    const initReviewCycle =
      reviewCycle || reviewCycles?.find(cycle => Number(cycle.offset) === 0)

    const emptyMetric: DeepPartial<GoalKpiDetails> = {
      ...getEntityProps(),
      is_company: values.is_company,
      owner: values.owner,
      targets: [{ review_cycle: initReviewCycle }],
      goal: { id: values.id },
      tempId: Date.now(), // need a number value before metrics are submitted and have actual id. Used for targeting the form for copying data
    }
    values.kpis.push(emptyMetric)
  }

  useEffect(() => {
    if (!values.kpis.length && !isFetching) {
      addEmptyMetric()
    }
  }, [isFetching])

  return (
    <CellWithItem
      icon="TurboTransfer"
      title="Metric"
      description="What are the metrics and success criteria?"
    >
      <GoalMetricsItems metrics={values.kpis} onCopy={onAddNewMetric} />
      {values.update_type?.id === 'target_based' ? (
        <ActionButton useIcon="Plus" onClick={() => onAddNewMetric()}>
          Add metric
        </ActionButton>
      ) : null}
    </CellWithItem>
  )
}
