import React from 'react'
import { Group } from '@revolut/ui-kit'
import { connect } from 'lape'
import { useSelector } from 'react-redux'
import { useQueryClient } from 'react-query'
import { useGlobalSettings } from '@src/api/settings'
import { PageBody } from '@src/components/Page/PageBody'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { API } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import Form from '@src/features/Form/Form'
import { timeOffSettingsRequests } from '@src/api/settings'
import { selectFeatureFlags, selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { timeOffSettingsLinkConfig } from './config'
import { getSettingsLinkConfig } from '../common/config'

const GeneralForm = () => {
  const queryClient = useQueryClient()
  const globalSettings = useGlobalSettings()
  const permissions = useSelector(selectPermissions)
  const featureFlags = useSelector(selectFeatureFlags)
  const disableEdit = !permissions.includes(PermissionTypes.ChangeTimeOffPreferences)

  const config = getSettingsLinkConfig({
    config: timeOffSettingsLinkConfig,
    permissions,
    featureFlags,
    globalSettings: globalSettings.settings,
    type: 'settings',
  })

  return (
    <>
      <PageHeader
        title="Time management settings"
        subtitle="Settings for the time off functionalities"
        backUrl={ROUTES.SETTINGS.ALL}
      />

      <PageBody>
        <Group>
          {config.timeOffCategories?.component}
          {config.timeOffPublicHolidays?.component}
          {config.timeOffWorkSchedules?.component}
          {config.timeOffRegimes?.component}
          {config.timeOffAttendancePolicies?.component}
          {config.timeOffAttendanceSettings?.component}
        </Group>
        <AutoStepper>
          <NewStepperTitle
            noAutoStep
            title="General"
            subtitle="Settings that apply for all time off functionalities"
          />
          <LapeNewSwitch
            itemTypeProps={{
              title: 'Enable the time off feature',
            }}
            name="enabled"
            disabled={disableEdit}
          />
        </AutoStepper>

        <PageActions>
          <NewSaveButtonWithPopup
            onAfterSubmit={() => {
              queryClient.invalidateQueries(API.TIME_OFF_SETTINGS)
            }}
            useValidator
            successText="Settings saved"
          />
        </PageActions>
      </PageBody>
    </>
  )
}

export const General = connect(() => (
  /** id: 1 is hardcoded on the BE */
  <Form api={timeOffSettingsRequests} forceParams={{ id: '1' }}>
    <GeneralForm />
  </Form>
))
