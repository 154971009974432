import React, { useState } from 'react'
import { connect } from 'lape'
import omit from 'lodash/omit'
import { Group, VStack, Text, MoreBar, TableWidget, Box, Token } from '@revolut/ui-kit'

import Form from '@src/features/Form/Form'
import { PageBody } from '@components/Page/PageBody'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ROUTES } from '@src/constants/routes'
import {
  archiveWorkSchedule,
  getWorkScheduleReviewEligibleEmployeesTableRequests,
  workScheduleBasicRequests,
  workScheduleRequests,
} from '@src/api/workSchedule'
import {
  WorkScheduleInterface,
  WorkScheduleReviewEligibleEmployeesInterface,
} from '@src/interfaces/workSchedule'
import { FormPreview } from '@components/FormPreview/FormPreview'
import {
  formatTimeInterval,
  getWorkingDaysString,
  getWorkingHoursNode,
} from '@src/apps/People/TimeManagement/WorkSchedule/helpers'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { RowInterface } from '@src/interfaces/data'
import {
  timeOffWorkScheduleReviewEmployeeColumn,
  timeOffWorkScheduleReviewRoleColumn,
} from '@src/constants/columns/timeOffWorkScheduleReview'
import { useTable } from '@components/Table/hooks'
import AdjustableTable from '@components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'

const ROW: RowInterface<WorkScheduleReviewEligibleEmployeesInterface> = {
  cells: [
    {
      ...timeOffWorkScheduleReviewEmployeeColumn,
      width: 200,
    },
    {
      ...timeOffWorkScheduleReviewRoleColumn,
      width: 200,
    },
  ],
}

const PreviewWorkScheduleInner = connect(() => {
  const { initialValues } = useLapeContext<WorkScheduleInterface>()

  const [archivePending, setArchivePending] = useState(false)

  const onDuplicate = async () => {
    const { data: initBasicStep } = await workScheduleBasicRequests.get({
      id: String(initialValues.id),
    })
    navigateTo(
      pathToUrl(ROUTES.APPS.TIME_MANAGEMENT.WORK_SCHEDULES.EDIT.BASICS, undefined, {
        copyFrom: String(initialValues.id),
      }),
      {
        initialValues: {
          ...omit(initBasicStep, 'id'),
          name: `${initialValues.name} - copy`,
        },
      },
    )
  }

  const table = useTable(
    getWorkScheduleReviewEligibleEmployeesTableRequests(initialValues.id!),
  )

  return (
    <PageWrapper>
      <PageHeader
        title={initialValues.name}
        backUrl={ROUTES.APPS.TIME_MANAGEMENT.WORK_SCHEDULES.LIST}
      />
      <PageBody>
        <VStack space="s-16">
          <MoreBar>
            <MoreBar.Action
              useIcon="Pencil"
              use={InternalLink}
              to={pathToUrl(ROUTES.APPS.TIME_MANAGEMENT.WORK_SCHEDULES.EDIT.BASICS, {
                id: initialValues.id,
              })}
            >
              Edit
            </MoreBar.Action>
            <MoreBar.Action useIcon="Copy" onClick={onDuplicate}>
              Duplicate & Modify
            </MoreBar.Action>
            <MoreBar.Action
              variant="negative"
              pending={archivePending}
              useIcon="Archive"
              onClick={async () => {
                if (!initialValues.id) {
                  return
                }
                try {
                  setArchivePending(true)
                  await archiveWorkSchedule(initialValues.id)
                } finally {
                  setArchivePending(false)
                }
              }}
            >
              Archive
            </MoreBar.Action>
          </MoreBar>
          <FormPreview title="General" data={initialValues}>
            <Group>
              <FormPreview.Item<WorkScheduleInterface> title="Policy name" field="name" />
              <FormPreview.Item<WorkScheduleInterface> title="Owner" field="owner" />
              <FormPreview.Item<WorkScheduleInterface>
                title="Days worked"
                insert={data => <Text>{getWorkingDaysString(data.working_days)}</Text>}
              />
              <FormPreview.Item<WorkScheduleInterface>
                title="Hours worked"
                insert={data => getWorkingHoursNode(data.working_days)}
              />
              <FormPreview.Item<WorkScheduleInterface>
                title="Allows employeees to change hours"
                field="shift_editable_by_employee"
                type="boolean"
              />
              <FormPreview.Item<WorkScheduleInterface>
                title="Shift on public holidays"
                field="normal_shift_on_public_holidays"
                type="boolean"
              />
            </Group>
          </FormPreview>
          <FormPreview title="Extra hours" data={initialValues}>
            <Group>
              <FormPreview.Item<WorkScheduleInterface>
                title="Night shift"
                insert={data => (
                  <Text>
                    {formatTimeInterval(
                      data.night_hours_time_start,
                      data.night_hours_time_end,
                    )}
                  </Text>
                )}
              />
              <FormPreview.Item<WorkScheduleInterface>
                title="Allows overtime"
                field="overtime_allowed"
                type="boolean"
              />
              <FormPreview.Item<WorkScheduleInterface>
                title="Overtime compensation"
                field="overtime_compensation.name"
              />
              <FormPreview.Item<WorkScheduleInterface>
                title="Requires approval?"
                field="require_approval_for_overtime_and_night_shifts"
                type="boolean"
              />
            </Group>
          </FormPreview>
          <Box>
            <Text variant="h6" color={Token.color.greyTone50} use="div" my="s-12">
              Eligible employees
            </Text>
            <TableWidget>
              <TableWidget.Table>
                <AdjustableTable
                  {...table}
                  dataType="Employees"
                  name={TableNames.WorkScheduleEligibilityReview}
                  noDataMessage="This schedule doesn't have auto-assigned employees."
                  row={ROW}
                  useWindowScroll
                />
              </TableWidget.Table>
            </TableWidget>
          </Box>
        </VStack>
      </PageBody>
    </PageWrapper>
  )
})

export const PreviewWorkSchedule = () => (
  <Form api={workScheduleRequests}>
    <PreviewWorkScheduleInner />
  </Form>
)
