import React from 'react'
import { Token, MoreBar, TableWidget } from '@revolut/ui-kit'

import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { PageBody } from '@components/Page/PageBody'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'
import AdjustableTable from '@components/Table/AdjustableTable'
import { RowInterface } from '@src/interfaces/data'
import { WorkScheduleListItemInterface } from '@src/interfaces/workSchedule'
import {
  timeOffWorkScheduleDaysWorkedColumn,
  timeOffWorkScheduleHoursWorkedColumn,
  timeOffWorkScheduleMembersColumn,
  timeOffWorkScheduleNameColumn,
  timeOffWorkScheduleStatusColumn,
} from '@src/constants/columns/timeOffWorkSchedule'
import { workScheduleTableRequests } from '@src/api/workSchedule'
import { useTable } from '@components/Table/hooks'
import Stat from '@components/Stat/Stat'

const ROW: RowInterface<WorkScheduleListItemInterface> = {
  linkToForm: ({ id }) =>
    navigateTo(pathToUrl(ROUTES.APPS.TIME_MANAGEMENT.WORK_SCHEDULES.PREVIEW, { id })),
  cells: [
    {
      ...timeOffWorkScheduleNameColumn,
      width: 200,
    },
    {
      ...timeOffWorkScheduleMembersColumn,
      width: 200,
    },
    {
      ...timeOffWorkScheduleDaysWorkedColumn,
      width: 200,
    },
    {
      ...timeOffWorkScheduleHoursWorkedColumn,
      width: 200,
    },
    {
      ...timeOffWorkScheduleStatusColumn,
      width: 200,
    },
  ],
}

export const WorkScheduleList = () => {
  const table = useTable(workScheduleTableRequests)

  return (
    <PageWrapper>
      <PageHeader title="Work Schedules" backUrl={ROUTES.SETTINGS.TIME_OFF.GENERAL} />
      <PageBody maxWidth={Token.breakpoint.xl}>
        <TableWidget>
          <TableWidget.Info>
            <Stat label="Total" val={table?.loading ? undefined : table?.data.length} />
          </TableWidget.Info>
          <TableWidget.Actions>
            <MoreBar>
              <MoreBar.Action
                useIcon="Plus"
                variant="accent"
                use={InternalLink}
                to={pathToUrl(ROUTES.APPS.TIME_MANAGEMENT.WORK_SCHEDULES.EDIT.BASICS)}
              >
                Create new schedule
              </MoreBar.Action>
            </MoreBar>
          </TableWidget.Actions>
          <TableWidget.Table>
            <AdjustableTable
              {...table}
              dataType="Work schedule"
              name={TableNames.WorkScheduleEligibilityReview}
              noDataMessage="Work schedules will appear here"
              row={ROW}
              useWindowScroll
            />
          </TableWidget.Table>
        </TableWidget>
      </PageBody>
    </PageWrapper>
  )
}
