import React from 'react'
import {
  Tile,
  Avatar,
  Tag,
  IconButton,
  Text,
  LineClamp,
  Ellipsis,
  Token,
} from '@revolut/ui-kit'
import { Pin, Cross } from '@revolut/icons'
import { formatTimeAgo } from '@src/utils/format'
import { compareAsc, formatDistanceToNow } from 'date-fns'

interface AnnouncementTileProps {
  onClick: () => void
  dismiss: () => void
  color?: string
  showIcon: boolean
  pinned: boolean
  icon?: string | null
  title: string
  date: string
  deadline?: string | null
}

export const AnnouncementTile = ({
  onClick,
  color,
  icon,
  showIcon,
  pinned,
  dismiss,
  title,
  date,
  deadline,
}: AnnouncementTileProps) => {
  return (
    <Tile
      variant="extra-small"
      onClick={onClick}
      bg={color || Token.color.black}
      preserveAspectRatio={false}
    >
      {showIcon && icon ? (
        <Tile.Avatar>
          <Avatar variant="brand" image={icon} bg={Token.color.white_30} />
        </Tile.Avatar>
      ) : null}
      {pinned ? (
        <Tile.Action>
          <Tag useIcon={Pin} variant="outlined" color={Token.color.white}>
            pinned
          </Tag>
        </Tile.Action>
      ) : (
        <Tile.Action>
          <IconButton
            aria-label="Remove"
            useIcon={Cross}
            size={16}
            color={Token.color.white_60}
            onClick={dismiss}
          />
        </Tile.Action>
      )}
      <Tile.Description color={Token.color.grey10}>
        <Text
          fontSize="small"
          use="div"
          color={Token.color.white}
          lineHeight={1.1}
          fontWeight={500}
        >
          <LineClamp max={2}>{title}</LineClamp>
        </Text>
        <Ellipsis>
          {deadline
            ? `${formatDistanceToNow(new Date(deadline))} ${
                compareAsc(Date.now(), new Date(deadline)) === 1 ? `overdue` : `left`
              }`
            : formatTimeAgo(date)}
        </Ellipsis>
      </Tile.Description>
    </Tile>
  )
}
