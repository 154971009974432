import React from 'react'
import { FormattedMessage } from 'react-intl'

import { FeatureFlags, GlobalSettings, PermissionTypes } from '@src/store/auth/types'
import { ROUTES } from '@src/constants/routes'
import { SettingsLinkItemConfig } from '../common/config'

type TimeOffSettingsItemId =
  | 'timeOffCategories'
  | 'timeOffPublicHolidays'
  | 'timeOffWorkSchedules'
  | 'timeOffRegimes'
  | 'timeOffAttendancePolicies'
  | 'timeOffAttendanceSettings'

export const timeOffSettingsLinkConfig: SettingsLinkItemConfig<TimeOffSettingsItemId>[] =
  [
    {
      id: 'timeOffCategories',
      section: 'Time off settings',
      icon: 'Controls',
      title: 'Time off categories',
      to: ROUTES.SETTINGS.TIME_OFF.CATEGORIES,
      subtitle: (
        <FormattedMessage
          id="settings.timeOff.categories"
          defaultMessage="Settings for managing leave categories"
        />
      ),
      permissions: [PermissionTypes.ViewTimeOffPolicyCategory],
    },
    {
      id: 'timeOffPublicHolidays',
      section: 'Time off settings',
      icon: 'CalendarDate',
      title: 'Public holidays',
      to: ROUTES.SETTINGS.TIME_OFF.HOLIDAYS,
      subtitle: (
        <FormattedMessage
          id="settings.documents.holidaysSubtitle"
          defaultMessage="Settings for managing public holidays by country & region"
        />
      ),
      permissions: [PermissionTypes.ViewPublicHolidays],
    },
    {
      id: 'timeOffWorkSchedules',
      section: 'Time off settings',
      icon: 'CalendarWeek',
      title: 'Work schedules',
      to: ROUTES.APPS.TIME_MANAGEMENT.WORK_SCHEDULES.LIST,
      subtitle: (
        <FormattedMessage
          id="settings.timeOff.workSchedules"
          defaultMessage="Settings for managing when employees work"
        />
      ),
      permissions: [PermissionTypes.ViewTimeOffPreferences],
      featureFlag: FeatureFlags.TimeOffSchedules,
    },
    {
      id: 'timeOffRegimes',
      section: 'Time off settings',
      icon: 'CalendarWeek',
      title: 'Regimes',
      to: ROUTES.SETTINGS.TIME_OFF.REGIMES,
      subtitle: (
        <FormattedMessage
          id="settings.timeOff.regimes"
          defaultMessage="Settings for managing when employees work"
        />
      ),
      permissions: [PermissionTypes.ViewTimeOffRegimes],
    },
    {
      id: 'timeOffAttendancePolicies',
      section: 'Time off settings',
      icon: 'CalendarWeek',
      title: 'Attendance policies',
      to: ROUTES.APPS.ATTENDANCE.SCHEDULER,
      subtitle: (
        <FormattedMessage
          id="settings.timeOff.attendancePolicies"
          defaultMessage="Settings for managing attendance policies"
        />
      ),
      permissions: [
        PermissionTypes.ViewDirectReportsSchedule,
        PermissionTypes.ViewShiftsSummary,
        PermissionTypes.ViewShiftsBreakdown,
        PermissionTypes.ViewSchedulingPolicy,
      ],
      globalSetting: GlobalSettings.AttendanceEnabled,
    },

    {
      id: 'timeOffAttendanceSettings',
      section: 'Time off settings',
      icon: 'CalendarWeek',
      title: 'Attendance settings',
      to: ROUTES.SETTINGS.ATTENDANCE,
      subtitle: (
        <FormattedMessage
          id="settings.timeOff.attendance"
          defaultMessage="Settings for the attendance app"
        />
      ),
      permissions: [PermissionTypes.ViewAttendanceTrackingPreferences],
      hideIfCommercial: true,
    },
  ]
