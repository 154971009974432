import React, { useEffect, useRef, useState } from 'react'
import { Box, VStack } from '@revolut/ui-kit'

import SectionTitle from '@src/pages/OnboardingChecklist/components/SectionTitle'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { navigateReplace } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { PageActions } from '@components/Page/PageActions'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { WorkScheduleBasicStepInterface } from '@src/interfaces/workSchedule'
import { useQuery } from '@src/utils/queryParamsHooks'
import LapeNewSwitch from '@components/Inputs/LapeFields/LapeNewSwitch'
import { LapeRadioSwitch } from '@src/pages/OnboardingChecklist/components/RadioSwitch'

import { CommonTabsProps } from '../common'
import { DaysIndividualSettingsWidget } from './DaysIndividualSettingsWidget'
import { DaysMultiSelectWidget } from './DaysMultiSelectWidget'
import { daysOptions } from './constants'
import { DayOption } from './common'
import {
  useGetWorkScheduleBasics,
  workScheduleExtraHoursRequests,
} from '@src/api/workSchedule'
import omit from 'lodash/omit'
import Loader from '@components/CommonSC/Loader'

type Props = CommonTabsProps
export const WorkScheduleBasics = ({ nextTabPath }: Props) => {
  const { query } = useQuery<{ copyFrom?: string }>()
  const { values } = useLapeContext<Partial<WorkScheduleBasicStepInterface>>()

  useEffect(() => {
    if (!query.copyFrom && values.define_hours_individually == null) {
      values.define_hours_individually = false
    }
  }, [])

  const { data: copySource, isLoading: isCopySourceLoading } = useGetWorkScheduleBasics(
    query.copyFrom,
  )

  useEffect(() => {
    if (query.copyFrom && copySource) {
      values.name = `${copySource.name} - copy`
      values.define_hours_individually = copySource.define_hours_individually
      values.normal_shift_on_public_holidays = copySource.normal_shift_on_public_holidays
      values.shift_editable_by_employee = copySource.shift_editable_by_employee
      values.working_days = copySource.working_days
    }
  }, [query.copyFrom, copySource])

  const getSelectedWorkDays = (newValues: Partial<WorkScheduleBasicStepInterface>) =>
    daysOptions.filter(dayOption =>
      newValues.working_days?.some(workingDay => {
        return workingDay.day === dayOption.id
      }),
    )

  const [workDaysErrors, setWorkDaysErrors] = useState([])
  const [selectedDays, setSelectedDays] = useState<DayOption[]>(
    getSelectedWorkDays(values),
  )

  const updateSelectedDaysWithOrder = (newDays: DayOption[]) => {
    setSelectedDays(
      daysOptions.filter(dayOption => newDays.some(day => day.id === dayOption.id)),
    )
  }
  const addSelectedDayWithOrder = (newDay: DayOption) => {
    if (!selectedDays.some(day => day.id === newDay.id)) {
      updateSelectedDaysWithOrder([...selectedDays, newDay])
    }
  }

  const nameInputRef = useRef<HTMLDivElement>(null)
  const workDaysSettingsRef = useRef<HTMLDivElement>(null)

  if (isCopySourceLoading) {
    return <Loader />
  }
  return (
    <>
      <VStack mt="s-16" space="s-16">
        <Box ref={nameInputRef}>
          <SectionTitle title="What should your work schedule be called?" />
          <LapeNewInput name="name" label="Work schedule name" required />
        </Box>
        <Box ref={workDaysSettingsRef}>
          <SectionTitle title="What hours do employees in this schedule work?" />
          <LapeNewSwitch
            itemTypeProps={{
              title: 'Define hours for each day individually',
            }}
            name="define_hours_individually"
          />
        </Box>
        {values.define_hours_individually ? (
          <DaysIndividualSettingsWidget
            selectedDays={selectedDays}
            updateSelectedDays={updateSelectedDaysWithOrder}
            addSelectedDay={addSelectedDayWithOrder}
            errors={workDaysErrors}
            clearErrors={() => setWorkDaysErrors([])}
          />
        ) : (
          <DaysMultiSelectWidget
            selectedDays={selectedDays}
            updateSelectedDays={updateSelectedDaysWithOrder}
            errors={workDaysErrors}
            clearErrors={() => setWorkDaysErrors([])}
          />
        )}
        <Box>
          <SectionTitle
            title="Should employees be allowed to change the hours set above?"
            subtitle="This defines if employees are able to change the default hours for shift set above"
          />
          <LapeRadioSwitch<boolean>
            name="shift_editable_by_employee"
            variant="horizontal"
            options={[
              {
                id: 'yes',
                label: 'Yes',
                description:
                  'Employees are able to change their default hours, but keeping the same duration.',
                value: true,
              },
              {
                id: 'no',
                label: 'No',
                description: 'Employees should not be able to edit these hours',
                value: false,
              },
            ]}
          />
        </Box>
      </VStack>
      <PageActions pt="s-64">
        <NewSaveButtonWithPopup
          noPopup
          useValidator
          hideWhenNoChanges={false}
          onAfterSubmit={async res => {
            let initialValues
            if (query.copyFrom) {
              const { data: nextStepData } = await workScheduleExtraHoursRequests.get({
                id: query.copyFrom,
              })
              initialValues = omit(nextStepData, 'id')
            }
            navigateReplace(pathToUrl(nextTabPath, { id: res.id }, query), {
              initialValues,
            })
          }}
          errorHandler={error => {
            const errorData = error.response?.data

            if (error.response?.status === 400 && errorData?.working_days) {
              setWorkDaysErrors(errorData.working_days)
              const errorInputRef = errorData.name ? nameInputRef : workDaysSettingsRef
              setTimeout(() => {
                errorInputRef.current?.scrollIntoView({
                  behavior: 'smooth',
                  block: 'end',
                })
              }, 50)
            } else {
              throw error
            }
          }}
        >
          Next
        </NewSaveButtonWithPopup>
      </PageActions>
    </>
  )
}
