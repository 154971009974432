import React, { useEffect } from 'react'
import { ScoreType, ViewType } from '../types'
import { IdAndName } from '@src/interfaces'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import {
  engagementResultsAverageDistributionColumn,
  engagementResultsAverageScoreColumn,
  engagementResultsCategoryNameColumn,
  engagementResultsNpsDistributionColumn,
  engagementResultsNpsScoreColumn,
  engagementResultsTrendAverageScoreColumn,
  engagementResultsTrendNpsScoreColumn,
} from '@src/constants/columns/engagementResults'
import { Box, Token } from '@revolut/ui-kit'
import { useTable } from '@src/components/Table/hooks'
import { EngagementResultInterface } from '@src/interfaces/engagement'
import { getSurveyAdminResultDriversTableRequests } from '@src/api/engagement'

interface Props {
  view: ViewType
  scoreType: ScoreType
  selectedRound?: IdAndName
}

const trendColumnTooltip = (
  <Box color={Token.color.background} p="s-8" minWidth={250}>
    This indicates how much the score for this category has increased or decreased since
    the last time it was asked to employees
  </Box>
)

const ROW = {
  cells: [
    { ...engagementResultsCategoryNameColumn, width: 250 },
    { ...engagementResultsAverageScoreColumn, width: 105 },
    {
      ...engagementResultsNpsScoreColumn,
      width: 105,
    },
    { ...engagementResultsAverageDistributionColumn, width: 300 },
    {
      ...engagementResultsNpsDistributionColumn,
      width: 300,
    },
    {
      ...engagementResultsTrendAverageScoreColumn,
      width: 95,
      headerTooltip: trendColumnTooltip,
    },
    {
      ...engagementResultsTrendNpsScoreColumn,
      width: 95,
      headerTooltip: trendColumnTooltip,
    },
  ],
}

export const ResultCategories = ({ view, scoreType, selectedRound }: Props) => {
  const table = useTable<EngagementResultInterface>(
    getSurveyAdminResultDriversTableRequests({ roundId: selectedRound?.id }),
  )

  useEffect(() => {
    if (selectedRound?.id) {
      table.refresh()
    }
  }, [selectedRound])

  const hiddenCells = {
    [engagementResultsAverageScoreColumn.idPoint]: scoreType === 'engagement',
    [engagementResultsAverageDistributionColumn.idPoint]: scoreType === 'engagement',
    [engagementResultsTrendAverageScoreColumn.idPoint]: scoreType === 'engagement',
    [engagementResultsNpsScoreColumn.idPoint]: scoreType === 'average',
    [engagementResultsNpsDistributionColumn.idPoint]: scoreType === 'average',
    [engagementResultsTrendNpsScoreColumn.idPoint]: scoreType === 'average',
  }

  return view === 'table' ? (
    <AdjustableTable
      name={TableNames.EngagementSurveysResultCategories}
      row={ROW}
      enableSettings={false}
      rowHeight="large"
      hiddenCells={hiddenCells}
      {...table}
    />
  ) : null
}
